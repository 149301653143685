import React from 'react';
import { Form, FormGroup, Input } from '@bigcommerce/big-design';
import CartItems from '../../components/cart/cart-items';
const SearchBox = (
    { 
        searchData, 
        placeholder, 
        disabled, 
        handleFocus,
        error,
        handleKeypress
    }) => {

    const handleSubmit = (event) => {
console.log('handleSubmit');
        event.preventDefault();
    }

    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Input
                    onKeyPress={handleKeypress}
                    label=""
                    description=""
                    placeholder={placeholder}
                    type="text"
                    onChange={searchData}
                    onFocus={handleFocus}
                    error={error}
                    disabled={JSON.parse(disabled) === true ? true : false}
                />
            </FormGroup>
        </Form>
    );
 <CartItems
           searchData={searchData}
            />
};
  
export default SearchBox;
