import React, { useState, useEffect } from 'react';
import SearchBox from '../../components/search-box/search-box';
import axios from 'axios';

const SearchCustomers = React.memo((
    { 
        searchClient,
        setSearchClient,
        clients,
        setClients,
        setClientsAddresses,
        setAddressCount,
        setIsSearchingClient,
        setDisableProductSearch,
        setCustomerState,
        cart,
        setCart,
        setFilterProducts,
        selectedAddress,
        setSelectedAddress,
        setPasswordEnable
    }) => {
    const [searchClientError, setSearchClientError] = useState('');
    // Hook
    const useDebounce = (value, delay) => {
        // State and setters for debounced value
        const [debouncedValue, setDebouncedValue] = useState(value);
        useEffect(
            () => {
                // Update debounced value after delay
                const handler = setTimeout(() => {
                    setDebouncedValue(value);
                }, delay);
                // Cancel the timeout if value changes (also on delay change or unmount)
                // This is how we prevent debounced value from updating if value is changed ...
                // .. within the delay period. Timeout gets cleared and restarted.
                return () => {
                    clearTimeout(handler);
                };
            },
            [value, delay] // Only re-call effect if value or delay changes
        );
        return debouncedValue;
    }

    const debouncedSearchClient = useDebounce(searchClient, 500);

    const onChange = (event) => {
	console.log('onchange');
        setSearchClient(event.target.value);
    }

    const searchClients = (searchTerm) => {
	console.log('inSearch');
        setCart('');
        axios.get(`/customers/getCustomerByEmail/${searchTerm}`)
        .then((response) => {
            setClients(response.data.customers);
            setClientsAddresses(response.data.customer_addresses);
            setAddressCount(response.data.address_count);
            setPasswordEnable(false);
        })
        .catch( error => {
	console.log('cartError');
            setClients([]);
            setDisableProductSearch(true);
            setFilterProducts([]);
        });
    }

    const handleFocus = (event) => {
        if (cart !== '') {
            setSearchClientError('Please make sure if you update client then cart items will be removed!');
console.log('check1');
        } else {
            setSearchClientError('');
}
    }

    useEffect( () => {
        setIsSearchingClient(false);
        if(clients.length > 0) {
            if (selectedAddress.length > 0) {
                setCustomerState(selectedAddress[0].state_or_province);
		console.log(selectedAddress[0].state_or_province);
                setDisableProductSearch(false);
            } else {
                setCustomerState('');
                setDisableProductSearch(true);
                setFilterProducts([]);
            }
        } else {
            setCustomerState('');
            setDisableProductSearch(true);
            setFilterProducts([]);
        }
    }, [clients, selectedAddress]);
    // Effect for API call
    useEffect(
        () => {
            if (debouncedSearchClient) {
                setIsSearchingClient(true);
                searchClients(debouncedSearchClient);
            } else {
                setIsSearchingClient(false);
                setClients([]);
            }
        },
        [debouncedSearchClient] // Only call effect if debounced search term changes
    );
  return (
        <SearchBox 
            searchData={onChange} 
            placeholder="Search for Clients"
            disabled="false"
            handleFocus={handleFocus}
            error={searchClientError}
        />
    );
});

export default SearchCustomers;
