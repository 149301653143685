import React, { useState, useEffect } from 'react';
import { 
    Flex, 
    FlexItem,
    Box, 
    H3 
} from '@bigcommerce/big-design';
import ProductList from '../../components/product/product-list';
import SearchProduct from '../../components/product/search-product';
import SearchCustomers from '../../components/customers/search-customers';
import CustomersList from '../../components/customers/customers-list';
import CartItems from '../../components/cart/cart-items';
import Loader from '../../components/loader/loader';
import axios from 'axios';

const HomePage = () => {
    const [products, setProducts] = useState([]);
    const [productAltStock, setProductAltStock] = useState({});
    const [filterProducts, setFilterProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [searchProduct, setSearchProduct] = useState('');
    const [qtyItems, setQtyItems] = useState({});
    const [priceItems, setPriceItems] = useState({});
    const [checkedItems, setCheckedItems] = useState({});
    const [selectedProductArray, setSelectedProductArray] = useState({});
    const [errorForm, setErrorForm] = useState({});
    const [searchClient, setSearchClient] = useState('');
    const [clients, setClients] = useState([]);
    const [clientsAddresses, setClientsAddresses] = useState([]);
    const [addressCount, setAddressCount] = useState(0);
    const [isSearchingClient, setIsSearchingClient] = useState(false);
    const [disableProductSearch, setDisableProductSearch] = useState(true);
    const [customerState, setCustomerState] = useState('');
    const [cart, setCart] = useState('');
    const [selectedAddress, setSelectedAddress] = useState([]);
    const [disableBtn, setDisableBtn] = useState(true);
    const [storeDetails, setStoreDetails] = useState('');
    const [passwordEnable, setPasswordEnable] = useState(false);
    const [setSearchField] = useState(false);   

    const fetchProducts = () => {
        axios.get('/products')
        .then((response) => {
            setProducts(response.data.products);
            setLoading(false);
        })
        .catch( error => {
            setError(true);
            console.log(`Error: ${error}`)
        });
    }

    const fetchStoreDetails = () => {
        axios.get('/store')
        .then((response) => {
            setStoreDetails(response.data.storeDetails);
        })
        .catch( error => {
            console.log(`Error: ${error}`)
        });
    }

    const fetchProductAltStock = () => {
        setDisableBtn(true);
        var state = (customerState === '') ? 'default' :  customerState;
        axios.post('/products/readcsv', {
            customer_state: state
        })
        .then((response) => {
            setProductAltStock(response.data.products);
            setIsSearchingClient(false);
            if (searchProduct.length > 2 && disableProductSearch === false) {
                setDisableBtn(false);
            }
        })
        .catch( error => {
            console.log(`Error: ${error}`)
        });
    }

    //checkbox change listener
    const handleSelected = (event) => {
        const productArray = (event.target.id).split("_");
        const productSKU = productArray[1];
        window.scrollTo(0, 0);
        setCheckedItems({...checkedItems, [productSKU] : event.target.checked });
	const quantSku = 'quantity_' + productSKU; 
         const timer =    setTimeout(() => {
                        document.getElementById(quantSku).focus();
			document.getElementById(quantSku).select();
                          }, 1000);
          return () => clearTimeout(timer); 
    }
    

    useEffect(() => {
        const checkedProducts = products.filter((product) => {
            return checkedItems[product.sku];
        });
        setSelectedProductArray(checkedProducts);
    }, [checkedItems, products]);

    useEffect(() => {
        setFilterProducts(products);
    }, [products]);
    
    useEffect(() => {
        fetchProducts();
        fetchStoreDetails();
    }, []);

    useEffect(() => {
        fetchProductAltStock();
    }, [customerState]);
    

    if (loading) {
        return (
            <Loader error={error}/>
        );
    } else {
        return (
            <Flex>
                <FlexItem flexBasis="70%" paddingRight={{ mobile: 'none', tablet: 'medium', desktop: 'medium' }}>
                    <SearchProduct 
                        products={products}
                        productAltStock={productAltStock}
                        setSearchProduct={setSearchProduct}
                        qtyItems={qtyItems}
                        setQtyItems={setQtyItems}
                        checkedItems={checkedItems}
                        setCheckedItems={setCheckedItems}
                        errorForm={errorForm}
                        setErrorForm={setErrorForm}
                        filterProducts={filterProducts}
                        setFilterProducts={setFilterProducts}
                        disableProductSearch={disableProductSearch}
                        disableBtn={disableBtn}
                        setDisableBtn={setDisableBtn}
                        setPasswordEnable={setPasswordEnable}
                    />
                    {((searchProduct.length > 1  || selectedProductArray.length > 0) && clients.length > 0) ? 
                        <ProductList 
                            products={filterProducts} 
                            productQty={qtyItems} 
                            selectedItems={checkedItems} 
                            selectedProductArray={selectedProductArray} 
                            setCheckedItems={setCheckedItems}
                            handleSelected={handleSelected}
                            qtyItems={qtyItems}
                            setQtyItems={setQtyItems}
                            priceItems={priceItems}
                            setPriceItems={setPriceItems}
                            productAltStock={productAltStock}
                            errorForm={errorForm}
                            setErrorForm={setErrorForm}
                            cart={cart}
                            setCart={setCart}
                            clients={clients}
                            storeDetails={storeDetails}
                            passwordEnable={passwordEnable}
                        /> : ''}
                </FlexItem>
                <FlexItem flexBasis="30%" style={{maxWidth: "400px"}}>
                    <SearchCustomers 
                        searchClient={searchClient}
                        setSearchClient={setSearchClient}
                        clients={clients}
                        setClients={setClients}
                        setClientsAddresses={setClientsAddresses}
                        setAddressCount={setAddressCount}
                        setIsSearchingClient={setIsSearchingClient}
                        setDisableProductSearch={setDisableProductSearch}
                        setCustomerState={setCustomerState}
                        cart={cart}
                        setCart={setCart}
                        setFilterProducts={setFilterProducts}
                        selectedAddress={selectedAddress}
                        setSelectedAddress={setSelectedAddress}
                        setPasswordEnable={setPasswordEnable}
                    />
                    <CustomersList 
                        clients={clients}
                        clientsAddresses={clientsAddresses}
                        addressCount={addressCount}
                        isSearchingClient={isSearchingClient}
                        selectedAddress={selectedAddress}
                        setSelectedAddress={setSelectedAddress}
                        setFilterProducts={setFilterProducts}
                        setCart={setCart}
                        setCheckedItems={setCheckedItems}
                    />
                    {
                        cart !== '' && clients.length > 0
                        ? <CartItems
                                cart={cart}
                                setCart={setCart}
                                clients={clients}
                                selectedAddress={selectedAddress}
                                storeDetails={storeDetails}
                                setPasswordEnable={setPasswordEnable}
                               setSearchClient={setSearchClient}
                                  setClients={setClients}
			 />
                        : <Box 
                            backgroundColor="secondary20" 
                            padding="medium" 
                            marginTop="medium" 
                            border="box" 
                            borderRadius="normal"
                            >
                                <H3>Cart is empty !!!</H3>
                        </Box>

                    }
                    
                </FlexItem>
            </Flex>
        );
    }
    
};
  
export default HomePage;
