import React from 'react';
import './App.scss';
import Header from './components/header/header';
import HomePage from './pages/homepage/homepage';
import { Route, Switch } from 'react-router-dom';
import { GlobalStyles } from '@bigcommerce/big-design';

function App() {
  return (
    <div className="main">
      <GlobalStyles />
      <Header/>
      <Switch>
        <Route exact path='/' component={HomePage}></Route>
      </Switch>
    </div>
  );
}

export default App;
