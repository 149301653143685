import React, { useState } from 'react';
import jwt from 'jsonwebtoken';
import { v4 } from 'uuid';
import { 
    Box,
    Modal,
    Text,
    Button
} from '@bigcommerce/big-design';
import './cart.scss';

const CustomerLogin = ({
    customerId,
    cartParams,
    storeDetails,
    employeeLogs,
    setPasswordEnable,
    setCart,
      setSearchClient,
    setClients,
	refreshCart
}) => {
    const [isOpen, setIsOpen] = useState();

    const getLoginUrl = (
            customerId, 
            storeHash, 
            storeUrl, 
            clientId, 
            clientSecret
        ) => {
        const dateCreated = Math.round((new Date()).getTime() / 1000);
        const  payload = {
            "iss": clientId,
            "iat": dateCreated,
            "jti": v4(),
            "operation": "customer_login",
            "store_hash": storeHash,
            "customer_id": customerId,
            "redirect_to": "/cart.php"
        }
	console.log('payload');
	console.log(payload);
	console.log('cartP');
	console.log(cartParams);
        let token = jwt.sign(payload, clientSecret, {algorithm:'HS256'});
		console.log('token');
		console.log(token);

        return `${storeUrl}/customer-login-page/${cartParams}&login_token=${token}`;
    };
    let store_hash = sessionStorage.getItem("store_hash");
    let hash = store_hash.split("/");

	 const loginUrl = getLoginUrl(customerId, hash[1], storeDetails.secure_url, process.env.REACT_APP_CLIENT_ID, process.env.REACT_APP_CLIENT_SECRET);

        const openCustomerLoginPopUp = () => {
        const timer =  setTimeout(() => {
                     setIsOpen(true);
                }, 1500);
        return () => clearTimeout(timer);
       
    } 

    return (
        <Box>
            <Button onClick={() => { refreshCart(); openCustomerLoginPopUp(); }} id="customer-login-link">
                Proceed to Customer’s Cart
            </Button>
            <Modal
                actions={[
                    { 
                        text: 'Cancel', 
                        variant: 'secondary',
                        onClick: () => {
                            setIsOpen(false);
                        } 
                    },
                    { 
                        text: 'Confirm', 
                        onClick: () => {  
                            setIsOpen(false);
                             //Log Employee Details
                            employeeLogs();
                            setPasswordEnable(false);
			//	setCart('');
			 // setSearchClient('');
                          //  setClients('');
        var clientSearchFieldText = document.getElementById("bd-input-2").value ="";
         var productSearchFieldText = document.getElementById("bd-input-1").value ="";
                           window.open(
                                loginUrl,
                                '_blank'
                            );
                        }
                    }
                ]}
                header="Proceed to Customer's Cart"
                isOpen={isOpen}
                onClose={() => {
                        setIsOpen(false);
                    }
                }
                closeOnEscKey={true}
                closeOnClickOutside={false}
            >
                <Text> 
                    Are you sure you want to proceed to login as the customer? This URL can only be used once
                </Text>
            </Modal>

        </Box>
        
    );
}

export default CustomerLogin;
