import React, { useState } from 'react';
import { 
    Button,
    Modal,
    Form,
    FormGroup,
    Input,
    Flex,
    FlexItem,
    Message
} from '@bigcommerce/big-design';
import axios from 'axios';

const ProductEdits = ({ setPasswordEnable }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [password, setPassword] = useState('');
    const [isValid, setIsValid] = useState('');

    const onSubmit = (event) => {
        event.preventDefault();
        axios.post('/products/readPassword', {
            password
        })
        .then((response) => {
            setIsValid(response.data.response);
            setPasswordEnable(response.data.response);
            if (response.data.response === true) {
                setIsOpen(false);
                setPassword("");
            }
        })
        .catch( error => {
            console.log(error);
        });
    }
    const handleChange = (event) => setPassword(event.target.value);
    return (
        <>
            <Button 
                onClick={() => setIsOpen(true)}
            >
                Enable Price Edits
            </Button>

            <Modal
                header="Please Enter Password"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                closeOnEscKey={true}
                closeOnClickOutside={false}
            >
                { isValid ===false && 
                    <Message type="error" messages={[{ text: 'Incorrect Password' }]} marginVertical="medium" />
                }
                <Form 
                    onSubmit={onSubmit}
                >
                    <FormGroup>
                        <Input
                            label="Password"
                            placeholder="Password"
                            type="password"
                            value={password}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>
                    <FormGroup>
                        <Flex  justifyContent="flex-start">
                            <FlexItem>
                                <Button>Submit</Button>
                            </FlexItem>
                            <FlexItem paddingBottom="xLarge">
                                <Button 
                                    variant="subtle"
                                    onClick={() => setIsOpen(false)}
                                >
                                    Cancel
                                </Button>
                            </FlexItem>
                        </Flex>
                    </FormGroup>
                </Form>
            </Modal>
        </>
    );
}

export default ProductEdits;