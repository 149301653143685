import { Box } from '@bigcommerce/big-design';
import React from 'react';
import CustomersAddress from '../../components/customers/customers-address';

const CustomersList = React.memo((
    { 
        clients, 
        clientsAddresses,
        addressCount,
        isSearchingClient,
        selectedAddress,
        setSelectedAddress,
        setFilterProducts,
        setCart,
        setCheckedItems
    }) => {

    return (
        <Box 
            backgroundColor="secondary20" 
            padding="medium" 
            marginTop="medium" 
            border="box" 
            borderRadius="normal"
            className="customer-addresses"
        >
            {
                isSearchingClient 
                ? 'Searching ...' 
                : clients.length > 0 ? 
                    <CustomersAddress 
                        clients={clients}
                        clientsAddresses={clientsAddresses}
                        addressCount={addressCount}
                        selectedAddress={selectedAddress}
                        setSelectedAddress={setSelectedAddress}
                        setFilterProducts={setFilterProducts}
                        setCart={setCart}
                        setCheckedItems={setCheckedItems}
                    />
                    : 'No Client Selected'
            }
        </Box>
    );
});

export default CustomersList;