import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';

//set access token passed by Backend app to session
let url = new URL(window.location.href);

let access_token = url.searchParams.get("access_token");
if (access_token) {
  sessionStorage.setItem('access_token', access_token);
}

let store_hash = url.searchParams.get("store_hash");
if (store_hash) {
  sessionStorage.setItem('store_hash', store_hash);
}

let user_id = url.searchParams.get("user_id");
if (user_id) {
  sessionStorage.setItem('user_id', user_id);
}

let user_email = url.searchParams.get("user_email");
if (user_email) {
  sessionStorage.setItem('user_email', user_email);
}

//set default header with axios
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("access_token");
axios.defaults.headers.common['Store-Hash'] = sessionStorage.getItem("store_hash");

axios.interceptors.request.use(request => {
  // Edit request config
  return request;
}, error => {
  console.log(error);
  return Promise.reject(error);
});

axios.interceptors.response.use(response => {
  // Edit response config
  return response;
}, error => {
  console.log(error);
  return Promise.reject(error);
});


ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

