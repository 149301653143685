import React, { useState, useEffect } from 'react';
import { 
    Table, 
    Box, 
    Flex, 
    FlexItem, 
    Text, 
    Button, 
    InlineMessage, 
    Modal,
    ProgressCircle,
    Link
} from '@bigcommerce/big-design';
import { CloseIcon } from '@bigcommerce/big-design-icons';
import axios from 'axios';
import CustomerLogin from './customer-login';
import CartLinkModal from './cart-link-modal';
import SearchBox from '../../components/search-box/search-box';

const CartItems = React.memo(({ 
    cart, 
    setCart, 
    clients,
    selectedAddress,
    storeDetails,
    setPasswordEnable,
    setSearchClient,
    setClients
}) => {
    const [deleteError, setDeleteError] = useState('');
    const [errorType, setErrorType] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState('');
    const [loading, setLoading] = useState(false);
    const [isOrderOpen, setIsOrderOpen] = useState(false);
    const [orderCreatedMsg, setOrderCreatedMsg] = useState('');
    const [orderMsgHeading, setOrderMsgHeading] = useState('');
    const [isOpenCartLinkModal, setOpenCartLinkModal] = useState(false);
    const [cartParams, setCartParams] = useState('');
    const [cartTotal, setCartTotal] = useState(0);
    const [cartBaseTotal, setCartBaseTotal] = useState(0);  
	  const [cartLink, setCartLink] = useState('');
	 const [urlLink, setUrlLink] = useState('');
	 let physicalCartItemse = cart.line_items.physical_items;
     let digitalCartItemse = cart.line_items.digital_items;
     let cartLineItemse =   [...physicalCartItemse, ...digitalCartItemse];
	const [searchClientError, setSearchClientError] = useState('');

    useEffect(() => {
        const cartUrl = cart.redirect_urls.cart_url;
	setCartLink(cartUrl);
         const url = new URL(cartUrl);
	 setUrlLink(url);
        const cartSearch = url.search;
        setCartParams(cartSearch);

        if (cart) {
            let cartPrice = 0;
            let cartBasePrice = 0;
		let physicalCartItems = cart.line_items.physical_items;
            let digitalCartItems = cart.line_items.digital_items;
            let cartLineItems =   [...physicalCartItems, ...digitalCartItems];
            if (cartLineItems.length > 0) {
                cartLineItems.forEach(product => {
                    cartPrice = parseFloat(cartPrice) + parseFloat(product.extended_sale_price);
                    cartBasePrice = parseFloat(cartBasePrice) + parseFloat(product.extended_list_price);
                });
                setCartTotal(cartPrice);
                setCartBaseTotal(cartBasePrice);
            }
        }
    }, [cart]);

    const handleDelete = (id) => {
        setIsOpen(true);
        setDeleteItemId(id);
    }

    const deleteLineItem = () => {
        let cartId = cart.id;
        axios({
            method: 'delete',
            url: `/cart/delete/${cartId}/item/${deleteItemId}`
        })
        .then((response) => {
            const cartResponse = response.data.cartResponse;
            if (cartResponse.errors !== undefined) {
                setDeleteError(cartResponse.errors.id);
                setErrorType("error")
            } else if (cartResponse.data !== undefined) {
                setCart(cartResponse.data);
                setDeleteError('Item has been deleted Successfully!');
                setErrorType("success");
            } else if (cartResponse.length === 0) {
                setCart('');
                setDeleteError('Item has been deleted Successfully!');
                setErrorType("success");
            }
        })
        .catch( error => {
            console.log(`Error: ${error}`)
        });
    }

    const handleMessageClose = () => {
        setDeleteError('');
    }

    const createAnOrder = () => {
	console.log('create order');
        if (clients.length > 0 && cart !== '') {
            setLoading(true);
            let billing_address = selectedAddress;
            let customer_id = clients[0].id;
               let physicalCartItems = cart.line_items.physical_items;
            let digitalCartItems = cart.line_items.digital_items;
            let products =   [...physicalCartItems, ...digitalCartItems];
            axios.post('order', {
                billing_address,
                customer_id,
                products
            })
            .then((response) => {
                let orderResponse = response.data.orderResponse;
                if (orderResponse.hasOwnProperty("id")) {
		console.log('escalating');
                    employeeLogs();
                    setCart('');
                    let link = `${storeDetails.control_panel_base_url}/manage/orders/${orderResponse.id}/edit`;
                     window.open(link,"_blank");
			  setSearchClient('');
                     setClients('');
                    document.getElementById("bd-input-2").value ="";
                    document.getElementById("bd-input-1").value ="";
                    document.getElementById("bd-input-2").focus();
                } else {
                    setIsOrderOpen(true);
                    let msg = orderResponse[0].message;
                    setOrderMsgHeading('Error');
                    setOrderCreatedMsg(msg);
                    setPasswordEnable(false);
                }
                
                setLoading(false);
            })
            .catch( error => {
                console.log(`Error: ${error}`)
            });
            
        } else {
            setDeleteError('Something went wrong!');
        }
    }

    const openCartLinkModal = () => {
        setOpenCartLinkModal(true);
    }

 const refreshCart = () => {
	console.log(urlLink);
      let cartID = cart.id;
         axios.post(`/cart/refresh/${cartID}`)
        .then((response) => {
            const cartResponse = response.data.cartResponse;
	console.log('cartRes' + cartResponse);
           setCartLink(cartResponse);
	 const splitCartUrl = '?' + cartResponse.split('?')[1];
	setCartParams(splitCartUrl);
console.log('cartP' + cartParams);
        })
    }

	    const clearCart = () => {
        setSearchClient('');
        setClients('');
	  var clientSearchFieldText = document.getElementById("bd-input-2").value ="";
         var productSearchFieldText = document.getElementById("bd-input-1").value ="";
         document.getElementById("bd-input-2").focus();
		setCart('');
		 setSearchClientError('');
    }


    const employeeLogs = () => {
        let today = new Date();
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        const logData = {
            "user_email": sessionStorage.getItem("user_email"), 
            "user_id": sessionStorage.getItem("user_id"),
            "store_name": storeDetails.name,
            "date": date,
            "time": time,
            "customer_email": cart.email,
            "customer_id": cart.customer_id,
            "cart_total": cart.cart_amount
        };
        axios.post('/log/', {"data":logData})
        .then((response) => {
            console.log(response);
        })
        .catch( error => {
            console.log(error);
        });
    }

    const columns = [
        { header: 'SKU', hash: 'sku', render: ({ url, sku }) => {
            return <Link href={url} target="_blank" external>
                        { sku }
                    </Link> 
        }},
        { header: 'Name', hash: 'name', render: ({ name }) => name },
        { header: 'Price', hash: 'price', render: ({ 
            sale_price, 
            list_price, 
            extended_sale_price, 
            extended_list_price 
        }) => {
            let listPrice = parseFloat(list_price).toFixed(2);
            let salePrice = parseFloat(sale_price).toFixed(2);

            if (listPrice !== salePrice) {
                return <Box>
                        <Text strikethrough color="danger50">
                            ${listPrice}
                        </Text> ${salePrice}
                    </Box>;
            } else {
                return `$${listPrice}`;
            }
        }},
        { header: 'Qty', hash: 'qty', render: ({ quantity }) => quantity },
        { header: 'Delete', hash: 'delete', render: ({ id, is_mutable }) => 
            {
                if (is_mutable) {
                    return <Button 
                        variant="subtle"
                        onClick={() => handleDelete(id)} 
                    >
                        <CloseIcon 
                            color="danger50" 
                            size="xxLarge" 
                        />
                    </Button>
                    
                } else {
                    return '';
                }
            } 
        }
        
    ];
 return (
        <Box marginTop="medium">
            {
                deleteError !== '' 
                && 
                <InlineMessage 
                    type={errorType} 
                    messages={[{ text: deleteError }]} 
                    marginVertical="medium" 
                    onClose={handleMessageClose}
                />
            }
            <Table 
                keyField="id"
                columns={columns}
                items={cartLineItemse}
                itemName="Products"
                id="cart-table"
                stickyHeader
            />
            <Flex
                flexDirection="row"
                justifyContent="flex-end"
                marginTop="medium"
            >
                <FlexItem >
                    <Text bold marginRight="medium"> Total:</Text>
                </FlexItem>
                <FlexItem>
                    {cartTotal != cartBaseTotal
                    ? 
                    <Flex>
                        <Text 
                            strikethrough 
                            color="danger50" 
                            marginRight="small"
                        >
                            ${parseFloat(cartBaseTotal).toFixed(2)}
                        </Text> 
                        <Text>${parseFloat(cartTotal).toFixed(2)}</Text>
                    </Flex>
                    :  <Text>${parseFloat(cartBaseTotal).toFixed(2)}</Text>
                    }
                </FlexItem>
            </Flex>
            <Flex
                flexWrap="wrap"
            >
                   <FlexItem 
                    margin="small"
                >
                    <CustomerLogin 
                        customerId={clients[0].id}
                        cartParams={cartParams}
                        storeDetails={storeDetails}
                        employeeLogs={employeeLogs}
                        setPasswordEnable={setPasswordEnable}
                        setCart={setCart}
			refreshCart={refreshCart}
                    />
                    
                </FlexItem> 
                
		      <FlexItem 
                    margin="small"
                    id="refresh-order-wrapper"
                    class="cart-bottom-buttons-custom"
                >
                    <Button
                        id="refresh-order"
                        onClick={refreshCart}
                        disabled={loading}
                    >
                        Regenerate URLs
                    </Button>
                </FlexItem>                

		 <FlexItem 
                    margin="small"
		   id="complete-order-wrapper"
                    class="cart-bottom-buttons-custom"
                >
                    <Button
                        id="complete-order"
                        onClick={openCartLinkModal}
                    >
                        Copy Cart URL 
                    </Button>
                </FlexItem>               

		 <FlexItem 
                    margin="small"
                    id="place-order-wrap"
	             class="cart-bottom-buttons-custom"
                >
                    <Button
                        id="place-order"
                        onClick={createAnOrder}
                        disabled={loading}
                    >
                        Escalation Order
                        { loading &&  <ProgressCircle size="xSmall"/> }
                    </Button>
                </FlexItem>

                <FlexItem 
                    margin="small"
                     id="clear-cart-wrapper"
                    class="cart-bottom-buttons-custom"
                >
                    <Button
                        id="clear-cart"
                        onClick={clearCart}
                        disabled={loading}
                    >
                       Clear Cart
                    </Button>
                </FlexItem>                 

            </Flex>

            <Modal
                actions={[
                    { 
                        text: 'Cancel', 
                        variant: 'secondary',
                        onClick: () => {
                            setIsOpen(false);
                            setDeleteItemId('');
                        } 
                    },
                    { 
                        text: 'Delete', 
                        onClick: () => {
                            setIsOpen(false);
                            deleteLineItem(); 
                        }
                    }
                ]}
                header="Delete Item"
                isOpen={isOpen}
                onClose={() => {
                        setIsOpen(false);
                        setDeleteItemId('');
                    }
                }
                closeOnEscKey={true}
                closeOnClickOutside={false}
            >
                <Text> Do you want to delete this item from cart?</Text>
            </Modal>

            <Modal
                actions={[
                    { 
                        text: 'Ok', 
                        variant: 'primary',
                        onClick: () => {
                            setIsOrderOpen(false);
                            if (orderMsgHeading !== 'Error') {
                                setCart('');
                            }
                        } 
                    }
                ]}
                header={orderMsgHeading}
                isOpen={isOrderOpen}
                onClose={() => {
                        setIsOrderOpen(false);
                        if (orderMsgHeading !== 'Error') {
                            setCart('');
                        }
                    }
                }
                closeOnEscKey={true}
                closeOnClickOutside={false}
            >
                <Text> 
                    { orderCreatedMsg } 
                </Text>
            </Modal>

            { isOpenCartLinkModal === true && 
                <CartLinkModal 
                    isOpenCartLinkModal={isOpenCartLinkModal}
                    setOpenCartLinkModal={setOpenCartLinkModal}
                    cartLink={cartLink}
                    employeeLogs={employeeLogs}
                    setCart={setCart}
                    setPasswordEnable={setPasswordEnable}
               		setSearchClient={setSearchClient}
                    setClients={setClients}
		 />
            }
        </Box>
    )
});

export default CartItems;
