import { 
    Box,
    H3,
    HR,
    Radio
} from '@bigcommerce/big-design';
import React, { useState, useEffect } from 'react';
import './customers-styles.scss';

const CustomersAddress = React.memo((
    {   
        clients,
        clientsAddresses,
        addressCount,
        selectedAddress,
        setSelectedAddress,
        setFilterProducts,
        filterProducts,
        setCart,
        setCheckedItems
    }) => {
    const [selected, setSelected] = useState('');
    const [customerAddresses, setCustomerAddresses] = useState(clientsAddresses);
    
    useEffect(() => {
        const customerAddress = customerAddresses.filter(address => {
            return parseInt(address.id) === parseInt(selected);
        });

        const remainingAddress = customerAddresses.filter(address => {
            return parseInt(address.id) !== parseInt(selected);
        });

        setSelectedAddress(customerAddress);
	console.log('selected');
	console.log(customerAddress);
        setFilterProducts([]);
        setCart('');
        setCheckedItems({});
        //to move selected address on top
        const finalAddresses =  customerAddress.concat(remainingAddress);
        setCustomerAddresses(finalAddresses);
    }, [clients, selected ]);

    const selectAddress = (event) => {
        setSelected(event.target.value);
	console.log(event.target.value);
    }

    return (
        <Box>
            {
            addressCount > 0 
            ? 
            customerAddresses.map(address => {
                let full_name = address.first_name+" "+address.last_name;
                
                return (
                    <Box key={address.id}>
                        <Box>
                            <Box>
                                <H3>
                                    <Radio 
                                        value={address.id} 
                                        checked={parseInt(selected) === parseInt(address.id)} 
                                        onChange={selectAddress} 
                                        label={full_name}
                                    />
                                </H3>
                                <Box>
                                    {address.address1} {address.address2}
                                </Box>
                                <Box>
                                    {address.city}, {address.state_or_province}, {address.postal_code}
                                </Box>
                                <Box>{address.country}</Box>
                            </Box>
                        </Box>
                        <HR marginVertical="medium" />
                    </Box>
                );
            })  
            : 
            <Box>
                <Box marginBottom="small">
                    <strong>Address Not Found!!!!!</strong>
                </Box>
                <Box>Please add an address to the Customer.</Box>
            </Box>
            }
        </Box>
    );
});

export default CustomersAddress;
