import { ProgressCircle } from '@bigcommerce/big-design';

const Loader = ({error}) => {
    return (
        <div className="loader-wrapper">
            <ProgressCircle size="medium" error={error}/>
        </div>
    );
};

export default Loader;
