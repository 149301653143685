
import React, { useState, useEffect } from 'react';
import { 
    Modal, 
    Input,
    Text
} from '@bigcommerce/big-design';
const CartLinkModal = React.memo(({ 
    isOpenCartLinkModal,
    setOpenCartLinkModal,
    cartLink,
    employeeLogs,
    setPasswordEnable,
    setCart,
    setSearchClient,
    setClients
}) => {
     const [searchField, setSearchField] = useState('');
    const copyText = () => {
        /* Get the text field */
        let copyText = document.getElementById("cart-url");

        /* Select the text field */
        copyText.select();
        /* For mobile devices */
        copyText.setSelectionRange(0, 99999); 

        /* Copy the text inside the text field */
        document.execCommand("copy");
        //Log Employee Details
        employeeLogs();
        setPasswordEnable(false);
      //  setCart('');
       // setSearchClient('');
//	setClients('');
  //       setClients('');
var clientSearchFieldText = document.getElementById("bd-input-2").value ="";
         var productSearchFieldText = document.getElementById("bd-input-1").value ="";
    }
    return (
        <>
            <Modal
                actions={[
                    { 
                        text: 'Cancel', 
                        variant: 'subtle', 
                        onClick: () => setOpenCartLinkModal(false) 
                    },
                    { 
                        text: 'Copy URL', 
                        onClick: () => {
                            copyText();
                        }
                    },
                ]}
                header="Copy Cart URL"
                isOpen={isOpenCartLinkModal}
                onClose={() => setOpenCartLinkModal(false)}
                closeOnEscKey={true}
                closeOnClickOutside={false}
            >
                <Text>
                    Please note that this URL can only be used one time.
                </Text>
                <Input 
                    type="text"
                    value={cartLink}
                    onChange={() => null}
                    id="cart-url"
                />
            </Modal>
        </>
      );    
});

export default CartLinkModal;
