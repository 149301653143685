import React, { useState, useEffect } from 'react';
import SearchBox from '../../components/search-box/search-box';
import { 
    Button,
    Flex,
    FlexItem
} from '@bigcommerce/big-design';
import ProductEdits from './product-edits';

const SearchProduct = React.memo((
    {
        products,
        productAltStock,
        setSearchProduct, 
        qtyItems, 
        setQtyItems, 
        checkedItems, 
        setCheckedItems, 
        errorForm, 
        setErrorForm, 
        filterProducts,
        setFilterProducts,
        disableProductSearch,
        disableBtn,
        setDisableBtn,
        setPasswordEnable
    }) => {
    
    const [searchField, setSearchField] = useState('');
	let exactItems = [];
let relevantItems = [];
var exactSKU = [];
    const getFilteredProducts = (item) =>{
        const productsFromSearch = products.filter((product) => {
	  let cleanItemName = item.replace(/'/g,'');
                     let cleanProductName = product.name.replace(/'/g,'');
	
   if(product.search_keywords)
{
		   if(item.includes(" "))
                {
                    const itemArr = item.split(" ");
                    for (const itemArry of itemArr)
                    {

                         return (cleanProductName.toLowerCase().includes(cleanItemName.trim().toLowerCase()) || product.sku.toLowerCase().includes(item.trim().toLowerCase()) || product.description.toLowerCase().includes(item.trim().toLowerCase()) || product.search_keywords.toLowerCase().includes(itemArry.trim().toLowerCase())) && product.availability !== 'disabled' && product.base_variant_id !== null;
                    }
                }else{
                return (cleanProductName.toLowerCase().includes(cleanItemName.trim().toLowerCase()) || product.sku.toLowerCase().includes(item.trim().toLowerCase()) || product.description.toLowerCase().includes(item.trim().toLowerCase()) || product.search_keywords.toLowerCase().includes(item.trim().toLowerCase())) && product.availability !== 'disabled' && product.base_variant_id !== null;
            }
	}else
            {
		 
                return (cleanProductName.toLowerCase().includes(cleanItemName.trim().toLowerCase()) || product.sku.toLowerCase().includes(item.trim().toLowerCase()) || product.description.toLowerCase().includes(item.trim().toLowerCase())) && product.availability !== 'disabled' && product.base_variant_id !== null;
            }
        });
              
        return productsFromSearch;
}
    const getFilteredSKUs = (item) => {
        const productsSKUs = products.filter((product) => {
            return product.sku.toLowerCase() === item.trim().toLowerCase() && product.availability !== 'disabled' && product.base_variant_id !== null;
        });
        return productsSKUs;
    }

    const updateSearchField = (event) => {
        setSearchField(event.target.value);
    }

    useEffect(() => {
        if (searchField.length > 2) {
            setDisableBtn(false);
        } else {
            setDisableBtn(true);
        }
    }, [searchField]);

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.key === "Enter") {
            handleClick();
        }
    };

    //search for products
    const handleClick = () => {
        setSearchProduct(searchField);
        if (searchField.includes("\t")) {
            const excelColumns = searchField.split(" ");
            const excelResultArray = [];
            let quantityArray = qtyItems;
            let checkedArray = checkedItems;
            let errorArray = errorForm;
            excelColumns.forEach((column) => {
                let productExcelRow = column.split("\t");
                let productSKU = productExcelRow[0].trim();
                let productQty = productExcelRow[1].trim();
                const selectedSku = getFilteredSKUs(productSKU);
                
                if (selectedSku.length > 0) {
                    let quantityMax = '';
                    selectedSku.forEach((product) => {
                        if (product.inventory_tracking !== 'none') {
                            quantityMax = product.inventory_level;
                        }
                    });

                    quantityArray = {...quantityArray, [productSKU] :parseInt(productQty)};

                    checkedArray = {...checkedArray, [productSKU] :true};
                    
                    excelResultArray.push(selectedSku);

                    //update Error message
                    const quantityMin = 1;
                    
                    const qty = parseInt(productQty);

                    if (qty === '') {
                        errorArray = {...errorArray, [`${productSKU}_qty`]: `Please fill out this field.`};
                    } else if (qty < quantityMin) {
                        errorArray = {...errorArray, [`${productSKU}_qty`]: `Value must be greater than or equal to ${quantityMin}.`};
                    } else if (quantityMax === 0 && quantityMax !== '') {
                        errorArray = {...errorArray, [`${productSKU}_qty`]: `This item is not available.`};
                    } else if (qty > quantityMax && quantityMax !== '') {
                        errorArray = {...errorArray, [`${productSKU}_qty`]: `Value must be less than or equal to ${quantityMax}.`};
                    } else {
                        errorArray = {...errorArray, [`${productSKU}_qty`]: ""};
                    }
                } 
            });
            
            //convert multiple search result array to one array
            const resultandExcelArray = [].concat(...excelResultArray);
            //remove duplicate entries of products result from an array
            const uniqueExcelArray = resultandExcelArray.filter((c, index) => {
                return resultandExcelArray.indexOf(c) === index;
            });
            //set qunatity array
            setQtyItems(quantityArray);
            //set filter search result array
            setFilterProducts(uniqueExcelArray);
            //set selectedArray
            setCheckedItems(checkedArray);
            //set error messages
            setErrorForm(errorArray);
        } else {
            const updatedProducts = getFilteredProducts(searchField);
		let cleanSearchField = searchField.replace(/'/g,'');
		let i=0;
		let j=0;
		var searchFieldData = cleanSearchField.trim().toLowerCase();
	     updatedProducts.forEach(function(items,index)
        {
		var cleanSku = items.sku.toLowerCase();
		let cleanItem = items.name.replace(/'/g,'');
		  if(searchFieldData == cleanSku)
                    {
                        exactSKU[0] = items;
                    }
            if(items.sku.toLowerCase().includes(cleanSearchField.trim().toLowerCase()) || cleanItem.toLowerCase().includes(cleanSearchField.trim().toLowerCase()))
            {
               exactItems[i] = items;
		i++;
            }else{
                relevantItems[j] = items;
		j++;
            }
        });
		let combinedItems = [...exactSKU, ...exactItems, ...relevantItems];
            setFilterProducts(combinedItems);
        }
    }
    return (
        <Flex flexWrap="wrap">
            <FlexItem style={{minWidth: '350px'}} paddingRight='xSmall'>
                <SearchBox 
                    searchData={updateSearchField} 
                    placeholder="Search for items"
                    disabled={disableProductSearch}
                    handleKeypress={handleKeypress}
                />
            </FlexItem>
            <FlexItem>
                <Button 
                    actionType="normal" 
                    variant="primary" 
                    onClick={handleClick}
                    disabled={JSON.parse(disableBtn) === true ? true : false}
                >
                    Search
                </Button>
            </FlexItem>
            <FlexItem paddingLeft='xSmall'>
                {
                    filterProducts.length > 0 && <ProductEdits setPasswordEnable={setPasswordEnable}/>
                }
                
            </FlexItem>
            
        </Flex>
    );
});

export default SearchProduct;
